import React from 'react';
import { navigate } from 'gatsby';
import Swiper from 'react-id-swiper';
import Button from './Button';
import { ReactComponent as IconChatOff } from '../../../../assets/images/icon-chat-off.svg';
import { ReactComponent as IconReclamos } from '../../../../assets/images/icon-reclamos.svg';
import { ReactComponent as IconExit } from '../../../../assets/images/exit.svg';
import { ReactComponent as IconSupportOff } from '../../../../assets/images/icon-support-off.svg';
import { ReactComponent as IconWhatsApp } from '../../../../assets/images/icon-whatsapp.svg';
import {
  CardTitle,
  ContactWrapper,
  ContactCardTitle,
  ContactCard,
  ContactCardInfo,
  ContactCardFootNote,
  ContactCardPhone,
  NavigationButton,
} from './styled';
import 'swiper/css/swiper.css';

const API_BASE_WHATSAPP_SOPORTE =
  'https://api.whatsapp.com/send?phone=56937400691';
const API_BASE_WHATSAPP_VENTA =
  'https://api.whatsapp.com/send?phone=56937352538';

const breakpoints = {
  1: {
    slidesPerView: 'auto',
    spaceBetween: 20,
    centeredSlides: true,
    pagination: false,
  },
  769: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1025: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  1281: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
};

const CardSwiper = () => {
  return (
    <>
      <CardTitle>Contáctanos</CardTitle>
      <ContactWrapper>
        <Swiper
          breakpoints={breakpoints}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          renderPrevButton={() => (
            <NavigationButton className="swiper-button-prev" direction="prev" />
          )}
          renderNextButton={() => (
            <NavigationButton className="swiper-button-next" direction="next" />
          )}
        >
          <ContactCard>
            <ContactCardTitle>Chat Soporte</ContactCardTitle>
            <IconSupportOff />
            <ContactCardInfo>
              Si necesitas más información o tienes dudas, te ayudamos
            </ContactCardInfo>
            <ContactCardPhone>
              <p>Llámanos al</p>
              <span>600 600 1106</span>
              <p>O</p>
            </ContactCardPhone>
            <Button
              maxWidth="100%"
              padding="12px 10px"
              click={() => navigate(API_BASE_WHATSAPP_SOPORTE)}
            >
              <IconWhatsApp style={{ marginRight: '5px' }} />
              <span>WhatsApp Soporte</span>
            </Button>
            <ContactCardFootNote>Lunes a Domingo 24/7</ContactCardFootNote>
          </ContactCard>
          <ContactCard>
            <ContactCardTitle>Chat Venta</ContactCardTitle>
            <IconChatOff />
            <ContactCardInfo secondary>
              ¡La hacemos corta! Cuéntanos ahora y te ayudamos por WhatsApp.
              Fácil y rápido
            </ContactCardInfo>
            <Button
              maxWidth="100%"
              click={() => navigate(API_BASE_WHATSAPP_VENTA)}
            >
              <IconWhatsApp style={{ marginRight: '5px' }} />
              <span>WhatsApp Venta</span>
            </Button>
            <ContactCardFootNote>
              Lunes a Domingo 8:00 a 23:59 hrs
            </ContactCardFootNote>
          </ContactCard>
          <ContactCard>
            <ContactCardTitle>Formulario de reclamos</ContactCardTitle>
            <IconReclamos />
            <ContactCardInfo secondary>
              Cuentanos si nos equivocamos. Estamos trabajando para entregar un
              mejor servicio.
            </ContactCardInfo>
            <Button
              maxWidth="100%"
              color="bubbleGum"
              height="56px"
              click={() => navigate('/home/contactanos/reclamos')}
            >
              Formulario
            </Button>
            <ContactCardFootNote />
          </ContactCard>
          <ContactCard>
            <ContactCardTitle>Dar de baja</ContactCardTitle>
            <IconExit />
            <ContactCardInfo secondary>
              Ingresa si quieres dar de baja alguno de tus productos WOM
            </ContactCardInfo>
            <Button
              maxWidth="100%"
              color="bubbleGum"
              height="56px"
              click={() => navigate('/home/contactanos/dar-de-baja')}
            >
              Dar de baja
            </Button>
            <ContactCardFootNote />
          </ContactCard>
        </Swiper>
      </ContactWrapper>
    </>
  );
};

export default CardSwiper;
