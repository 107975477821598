import React from 'react';
import SEO from '../../../components/seo';
import CardSwiper from '../../../components/pages/home/Contactanos/CardSwiper';
import { AccountService } from '../../../services';
import { SiteLayout } from '../../../components/Layouts';

const Contactanos = () => {
  const userName = AccountService.getUserName();

  return (
    <>
      <SEO title="Contactanos" />
      <SiteLayout user={userName} mobileWithoutName>
        <CardSwiper />
      </SiteLayout>
    </>
  );
};

export default Contactanos;
