import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { minWidth, size } from '../../../../utils/device';
import { ReactComponent as NavPrevSvg } from '../../../../assets/images/swiper-nav-prev.svg';

const CardTitle = styled.h4`
  display: flex;
  margin: 20px 0 20px 0;
  font-size: 20px;
  font-weight: 500;
  @media ${minWidth(size.tablet)} {
    margin: 0 0 20px;
  }

  :before {
    content: '< | ';
    margin-right: 8px;
    @media ${minWidth(size.tablet)} {
      display: none;
    }
  }
`;

const ContactCardFootNote = styled.p`
  font-size: 14px;
  margin: 0;
  min-height: 48px;
  line-height: 19px;
  max-width: 200px;
  text-align: center;
  color: #7c6c8a;
  padding-top: 10px;
  font-weight: bold;
`;

const ContactCardInfo = styled.p`
  color: #7c6d89;
  font-size: 16px;
  line-height: 19px;
  max-width: 200px;
  text-align: center;
  margin-top: 20px;
  min-height: 74px;
  ${(p) => (p.secondary ? 'margin-bottom: 92px;' : 'margin-bottom: 20px;')}
`;

const transform = css`
  transform: translateY(-7px);
  background: white;
`;

const ContactCard = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  /* width: 30%; */
  width: 49%;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  transition-property: transform, background;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  position: relative;
  @media (min-width: 768px) {
    &:hover {
      ${transform}
    }
  }
  @media (max-width: 767px) {
    height: auto;

    &.swiper-slide-active {
      ${transform}
    }
  }
`;

const ContactCardTitle = styled.h1`
  align-self: flex-start;
  color: #381451;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
  margin-bottom: 40px;
  width: 100%;
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0 20px 0px;
  .swiper-container {
    padding-top: 7px;

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet-active {
        background-color: #e92070;
      }
    }
  }
`;

const ContactCardPhone = styled.div`
  color: #7c6d89;

  span {
    font-weight: bold;
  }

  p {
    margin-top: 5px;
    margin-bottom: 8px;
    text-align: center;
  }
`;

const NavigationButton = styled(NavPrevSvg)`
  top: 40%;
  left: 0;
  user-select: none;
  width: auto;
  :after {
    display: none;
  }

  ${({ direction }) =>
    direction === 'next' &&
    `
    left: unset;
    right: 0;
    transform: scale(-1, 1);
  `}
`;
NavigationButton.propTypes = {
  direction: PropTypes.oneOf(['prev', 'next']).isRequired,
};

export {
  CardTitle,
  ContactWrapper,
  ContactCardTitle,
  ContactCard,
  ContactCardInfo,
  ContactCardFootNote,
  ContactCardPhone,
  NavigationButton,
};
